// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-layout {
    font-size: 12px;
    font-weight: bold;
    border: none;
    justify-items: center;
    justify-content: center;
    z-index: 9999;
}

@media (hover: hover) and (pointer: fine) {
    .button-layout:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* hover 시 그림자 효과 */
    }
}

/* 모바일에서는 active 사용 */
@media (hover: none) and (pointer: coarse) {
    .button-layout:active {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* active 시 그림자 효과 */
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/CanvasPage/ButtonComponents.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI;QACI,0CAA0C,EAAE,mBAAmB;IACnE;AACJ;;AAEA,qBAAqB;AACrB;IACI;QACI,0CAA0C,EAAE,oBAAoB;IACpE;AACJ","sourcesContent":[".button-layout {\r\n    font-size: 12px;\r\n    font-weight: bold;\r\n    border: none;\r\n    justify-items: center;\r\n    justify-content: center;\r\n    z-index: 9999;\r\n}\r\n\r\n@media (hover: hover) and (pointer: fine) {\r\n    .button-layout:hover {\r\n        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* hover 시 그림자 효과 */\r\n    }\r\n}\r\n\r\n/* 모바일에서는 active 사용 */\r\n@media (hover: none) and (pointer: coarse) {\r\n    .button-layout:active {\r\n        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* active 시 그림자 효과 */\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
