// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    margin-top: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/HeaderFooter/HeaderFooterLayout.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".footer {\r\n    background-color: #343a40;\r\n    color: white;\r\n    text-align: center;\r\n    padding: 10px 0;\r\n    width: 100%;\r\n    margin-top: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
