// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */


.homepage {
    max-width: 1000px;
    padding: 50px;
}

.font1 {
    /* font-family: "Itim", cursive; */
    font-family: "PT Sans", sans-serif;
    font-size: 40px;
    display: inline;
    margin-right: 10px;
}

.font1-normal {
    font-size: 1.5em; /* 작은 폰트 크기 */
    display: inline; /* 텍스트를 인라인으로 유지 */
}

@media (max-width: 767px) {
    .font1-normal {
        display: none;
    }
}

.font2 {
    font-family: "Nanum Gothic", sans-serif;
    font-size: 15px;
    text-align: left;
}

.font3 {
    font-size: 15px;
}

.title {
    text-align: left;
    padding-top: 30px;
    padding-bottom: 10px;
    line-height: 170%;
}

.right-align {
    align-items: center;
    justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/Main.css"],"names":[],"mappings":"AAAA,wIAAwI;;;AAGxI;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,kCAAkC;IAClC,kCAAkC;IAClC,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB,EAAE,aAAa;IAC/B,eAAe,EAAE,kBAAkB;AACvC;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,uCAAuC;IACvC,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":["/* @import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */\r\n\r\n\r\n.homepage {\r\n    max-width: 1000px;\r\n    padding: 50px;\r\n}\r\n\r\n.font1 {\r\n    /* font-family: \"Itim\", cursive; */\r\n    font-family: \"PT Sans\", sans-serif;\r\n    font-size: 40px;\r\n    display: inline;\r\n    margin-right: 10px;\r\n}\r\n\r\n.font1-normal {\r\n    font-size: 1.5em; /* 작은 폰트 크기 */\r\n    display: inline; /* 텍스트를 인라인으로 유지 */\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .font1-normal {\r\n        display: none;\r\n    }\r\n}\r\n\r\n.font2 {\r\n    font-family: \"Nanum Gothic\", sans-serif;\r\n    font-size: 15px;\r\n    text-align: left;\r\n}\r\n\r\n.font3 {\r\n    font-size: 15px;\r\n}\r\n\r\n.title {\r\n    text-align: left;\r\n    padding-top: 30px;\r\n    padding-bottom: 10px;\r\n    line-height: 170%;\r\n}\r\n\r\n.right-align {\r\n    align-items: center;\r\n    justify-content: flex-end;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
