// FileUploader.js
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // 아이콘을 사용하기 위한 예제
import { FileContext } from './FileContext'; // FileContext를 import
import './FileUpLoader.css'

function FileUploader() {
    const { file, setFile, setFileName } = useContext(FileContext);
    const navigate = useNavigate();
    const [uploading, setUploading] = useState(false);

    // 파일 드롭 핸들러
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length) {
            setUploading(true);

            const acceptedFile = acceptedFiles[0];
            setFile(acceptedFile);

            const acceptedfileExtension = acceptedFile.name.lastIndexOf(".");
            const acceptedfileName = acceptedFile.name.substring(0, acceptedfileExtension)

            setFileName(acceptedfileName);
            console.log("File upload success.");
        } else {
            console.log("File upload fail.");
        }
    }, [setFile, setFileName]);

    // 파일 업로드 실패 핸들러
    const onDropRejected = useCallback((rejectedFiles) => {
        rejectedFiles.forEach(file => {
            if (file.errors.some(error => error.code === 'file-too-large')) {
                alert(`파일 크기는 10MB 이하여야 합니다: ${file.file.name}`);
            } else if (file.errors.some(error => error.code === 'file-invalid-type')) {
                alert(`허용되지 않은 파일 형식입니다: ${file.file.name}. PNG, JPG, JPEG 파일만 업로드 가능합니다.`);
            } else {
                alert(`파일 업로드 실패: ${file.file.name}`);
            }
        });
        console.log('Rejected files:', rejectedFiles);
    }, []);

    // 파일이 업로드되고 있으면 페이지 이동
    useEffect(() => {
        if (file && uploading) {
            navigate("/canvas");
        }
    }, [file, uploading, navigate]);

    // dropzone 훅 설정
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDropRejected,
        accept: {
            "image/png": [],
            "image/jpeg": [],
            "image/jpg": []
        },
        maxFiles: 1,
        maxSize: 10 * 1024 * 1024,
        multiple: false
    });

    return (
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop files here.<br /> </p> :
                    <div className="upload-container">
                        <CloudUploadIcon style={{ fontSize: 40 }}/>
                        <p>Drag and drop files here or click to upload.</p>
                    </div>
            }
        </div>
    );
}

export default FileUploader;
