import { Nav, Navbar, Container } from 'react-bootstrap';
import "./HeaderFooterLayout.css";

function Header() {
    return (
    <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="/">EraseEase</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            {/* <Nav.Link href="/">AI Eraser</Nav.Link> */}
          </Nav>
          <Navbar.Text style={{fontSize: '11px', color: "white", alignSelf: "flex-end"}} >v2.1.0</Navbar.Text>
        </Container>
    </Navbar>
    )
}

function Footer() {
    return (
        <footer className="footer bg-dark text-white mt-auto">
            <Container className="d-flex justify-content-center">
                <Nav>
                    <Nav.Link href="#" className="text-white">© Do Uk Kim, ALL RIGHTS RESERVED.</Nav.Link>
                </Nav>
            </Container>
        </footer>
    );
}

export {Header, Footer};