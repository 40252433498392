// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    border: 2px dashed #cccccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.2s ease;
    max-width: 500px;
    min-width: 300px;
    height: 120px;
}

.dropzone.active {
    background-color: #afacacbb;
    color: #1b1b1b
}

.dropzone p {
    margin: 0;
    font-size: 16px;
    color: #666666;
}

.upload-container {
    display: flex;
    align-items: center;
}

.upload-container p {
    margin-left: 10px; /* 아이콘과 텍스트 사이의 간격 */
}

.upload-container svg { /* 아이콘에 대한 스타일 */
    margin-right: 10px; /* 아이콘과 텍스트 사이의 간격 */
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/FileUploader/FileUpLoader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,0BAA0B;IAC1B,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,sCAAsC;IACtC,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,2BAA2B;IAC3B;AACJ;;AAEA;IACI,SAAS;IACT,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB,EAAE,oBAAoB;AAC3C;;AAEA,wBAAwB,gBAAgB;IACpC,kBAAkB,EAAE,oBAAoB;AAC5C","sourcesContent":[".dropzone {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 50px;\r\n    border: 2px dashed #cccccc;\r\n    border-radius: 4px;\r\n    background-color: #f9f9f9;\r\n    cursor: pointer;\r\n    transition: background-color 0.2s ease;\r\n    max-width: 500px;\r\n    min-width: 300px;\r\n    height: 120px;\r\n}\r\n\r\n.dropzone.active {\r\n    background-color: #afacacbb;\r\n    color: #1b1b1b\r\n}\r\n\r\n.dropzone p {\r\n    margin: 0;\r\n    font-size: 16px;\r\n    color: #666666;\r\n}\r\n\r\n.upload-container {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.upload-container p {\r\n    margin-left: 10px; /* 아이콘과 텍스트 사이의 간격 */\r\n}\r\n\r\n.upload-container svg { /* 아이콘에 대한 스타일 */\r\n    margin-right: 10px; /* 아이콘과 텍스트 사이의 간격 */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
