// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brush-slider {
    height: 7px; /* 슬라이더 트랙 두께 (핸들 높이보다 작게 설정) */
    background: #ddd; /* 슬라이더 트랙 배경 색상 */
    border-radius: 5px; /* 슬라이더 트랙 모서리 둥글게 */
    opacity: 0.7; /* 기본 투명도 */
}

/* 슬라이더 핸들 스타일 */
.brush-slider::-webkit-slider-thumb {
    appearance: none; /* 기본 핸들 스타일 제거 */
    width: 30px; /* 핸들 너비 (크게) */
    height: 30px; /* 핸들 높이 (크게) */
    border-radius: 50%; /* 핸들 둥글게 */
    background: black; /* 핸들 배경 색상 */
    cursor: pointer; /* 핸들 커서 스타일 */
    position: relative; /* 핸들 위치 조정 */
    z-index: 1; /* 핸들이 트랙 위에 나타나도록 설정 */
}`, "",{"version":3,"sources":["webpack://./src/pages/CanvasPage/BrushComponents.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,+BAA+B;IAC5C,gBAAgB,EAAE,kBAAkB;IACpC,kBAAkB,EAAE,oBAAoB;IACxC,YAAY,EAAE,WAAW;AAC7B;;AAEA,gBAAgB;AAChB;IACI,gBAAgB,EAAE,iBAAiB;IACnC,WAAW,EAAE,eAAe;IAC5B,YAAY,EAAE,eAAe;IAC7B,kBAAkB,EAAE,WAAW;IAC/B,iBAAiB,EAAE,aAAa;IAChC,eAAe,EAAE,cAAc;IAC/B,kBAAkB,EAAE,aAAa;IACjC,UAAU,EAAE,uBAAuB;AACvC","sourcesContent":[".brush-slider {\r\n    height: 7px; /* 슬라이더 트랙 두께 (핸들 높이보다 작게 설정) */\r\n    background: #ddd; /* 슬라이더 트랙 배경 색상 */\r\n    border-radius: 5px; /* 슬라이더 트랙 모서리 둥글게 */\r\n    opacity: 0.7; /* 기본 투명도 */\r\n}\r\n\r\n/* 슬라이더 핸들 스타일 */\r\n.brush-slider::-webkit-slider-thumb {\r\n    appearance: none; /* 기본 핸들 스타일 제거 */\r\n    width: 30px; /* 핸들 너비 (크게) */\r\n    height: 30px; /* 핸들 높이 (크게) */\r\n    border-radius: 50%; /* 핸들 둥글게 */\r\n    background: black; /* 핸들 배경 색상 */\r\n    cursor: pointer; /* 핸들 커서 스타일 */\r\n    position: relative; /* 핸들 위치 조정 */\r\n    z-index: 1; /* 핸들이 트랙 위에 나타나도록 설정 */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
