import React from 'react';
import { Helmet } from 'react-helmet';

import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import FileUploader from './FileUploader/FileUpLoader';

import thumbnail from "./thumbnail.mp4";
import favicon from "./custom_favicon.png"

import './Main.css';

function Title() {
    return (
        <>
        <div className="title">
            <h1 className="font1">EraseEase</h1><span className="font1-normal">: Remove unwanted objects from photos in seconds</span>
        </div>
        <br/>
        <p className="font2">Remove unwanted people, distracted objects, and complex backgrounds from photos in seconds ✨</p>
        <br/>
        </>
    );
}

function ThumbnailImage() {
    return (
        <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center"}}>
            <video
                src={thumbnail}
                alt="Thumbnail"
                style={{ width: '100%', height: 'auto', maxWidth:'240px', borderRadius:"20px"}}
                autoPlay
                loop
                muted
                playsInline
             />
        </div>
        <br></br>
        </>
    );
}

function Description() {
    return (
        <div className="font3" style={{ padding: '20px', textAlign: "left", textIndent:"-1em"}}>
            <p>◆ Uploaded images are not stored on the server.</p>
            <p>◆ Better results can be achieved with more detailed touches.</p>
            <p>◆ Bug reports and inquiries (rec7748@gmail.com)</p>
        </div>
    );
}

function Homepage() {
    return (
        <>
        <div>
        <Helmet>
            <title>EraseEase: Remove unwanted objects from photos in seconds</title>
            <meta name="description" content="Remove unwanted people, distracted objects, and complex backgrounds from photos in seconds" />
            <meta name="keywords" content="remove objects from photo, magic eraser, erase objects from photo, remove people from photo, ai 지우개,매직 지우개, 사진에서 사람 지우기, " />
            <meta name="author" content="Do Uk Kim" />
            <meta property="og:title" content="EraseEase: Remove unwanted objects from photos in seconds" />
            <meta property="og:description" content="Remove unwanted people, distracted objects, and complex backgrounds from photos in seconds" />
            <meta property="og:image" content={favicon} />
            <meta property="og:url" content="https://doha-eraser.site" />
            <meta name="twitter:title" content="EraseEase: Remove unwanted objects from photos in seconds" />
            <meta name="twitter:description" content="Remove unwanted people, distracted objects, and complex backgrounds from photos in seconds" />
            <meta name="twitter:image" content={favicon} />
            <meta name="twitter:card" content={favicon} />
        </Helmet>
        <Container className="homepage">
            <Title />
            <Row className="mt-4">
                <Col md={4}>
                    <ThumbnailImage />
                </Col>
                <Col md={7} className="d-flex flex-column align-items-center">
                    <FileUploader />
                    <Description />
                </Col>
            </Row>
        </Container>
    </div>
    </>
    );
}

export default Homepage;