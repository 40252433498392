import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import  Homepage from "./pages/HomePage/Main.js";
import { FileProvider } from './pages/HomePage/FileUploader/FileContext.js';

import Canvas from "./pages/CanvasPage/Canvas.js";
import {Header, Footer} from "./pages/HeaderFooter/HeaderFooterLayout.js";

function App() {

  const location = useLocation();
  const isCanvasRoute = location.pathname === '/canvas';

  return (
    <>
    <div className={`App ${isCanvasRoute ? 'canvas-background' : ''}`}>
      <Header />
        <FileProvider>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/canvas" element={<Canvas />} />
            </Routes>
        </FileProvider>
      <Footer />
    </div>
    </>
  );
}

export default App;
