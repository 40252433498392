/* eslint-disable */
import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { Container, Row } from "react-bootstrap";

import { FileContext } from '../HomePage/FileUploader/FileContext.js';
import LoadImage from "./loadImage.js"
import { startDrawing, finishDrawing, draw, redrawCanvas } from './CanvasHandlers.js';
import { useCanvasLayout, useCanvasContext } from './CanvasHandlers.js';

import { BrushSlider } from './BrushComponents.js';
import { handleInference } from "./Inference.js";

import "./Canvas.css";

import { LineRemoverButton, EraseButton, DownloadButton, PrevFrameButton, NextFrameButton  } from './ButtonComponents.js';
import {Background, ShowBrushSreen, BrushPreviewScreen, LoadingScreen} from "./CanvasComponents.js"


function Canvas() {
    const brushColor = "rgb(255, 0, 0)";
    const maxCanvasLayout = 640;
    const backgroundImageRef = useRef(null);
    const canvasRef = useRef(null);
    
    const {file, setFile, fileName} = useContext(FileContext);
    const backgroundImage = LoadImage(file);

    const { width: canvasWidth, height: canvasHeight } = useCanvasLayout(backgroundImage, maxCanvasLayout);
    
    const [bgList, setBgList] = useState([file]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [isDrawing, setIsDrawing] = useState(false);
    const ctx = useCanvasContext(canvasRef, canvasWidth, canvasHeight);
    const [lines, setLines] = useState([]);
    
    const [brushPos, setBrushPos] = useState({ x: 0, y: 0 });
    const [showBrush, setShowBrush] = useState(false);
    const [brushSize, setBrushSize] = useState(20);
    const [brushPreview, setbrushPreview] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    
    if (!file) {
        return <Navigate to="/" replace />;
    }

    useEffect(() => {
        if (bgList.length > 0) {
            setFile(bgList[currentIndex]);
        }
    }, [bgList, currentIndex]);

    useEffect(() => {
        redrawCanvas(canvasRef, ctx, lines);
    }, [isLoading, currentIndex]);


    const handleBrushSizeChange = (e) => {
        setbrushPreview(true);
        setBrushSize(Number(e.target.value));
    };

    const handleBrushPreviewEnd = () => setbrushPreview(false);


    const handleUndoBackground = () => {
        if (bgList.length > 1 && currentIndex > 0) {
            setCurrentIndex(prevIndex => prevIndex - 1);
            setLines([]);
        }
    };

    const handleRedoBackground = () => {
        if (bgList.length > 0 && currentIndex < bgList.length - 1) {
            setCurrentIndex(prevIndex => prevIndex + 1);
            setLines([]);
        }
    };

    const handleDownload = () => {
        if (backgroundImageRef.current) {
            const link = document.createElement('a');
            link.href = backgroundImage?.src;
            link.download = fileName + '_result.png';
            // Append to body and trigger click
            document.body.appendChild(link);
            link.click();
            // Clean up
            document.body.removeChild(link);
        }
    };

    const handleUndo = () => {
        if (lines.length === 0) return;
    
        setLines(prevLines => {
            // Remove the last line
            const newLines = prevLines.slice(0, -1);
            redrawCanvas(canvasRef, ctx, newLines);
            return newLines;
        });
    };

    const handleMouseEnter = (setShowBrush) => {
        setShowBrush(true);
    }
    
    const handleMouseLeave = (setShowBrush) => {
        setShowBrush(false);
    };

    const handleContextMenu = useCallback((event) => {
        event.preventDefault(); // 우클릭 차단
        return false;
    }, []);

    return (
        <Container className="canvas-container mt-5">
            {canvasWidth > 0 ? (
                <>
                <div
                    className="canvas-overlay-container"
                    style={{
                        '--canvas-width': `${canvasWidth}px`,
                        '--canvas-height': `${canvasHeight}px`,
                        }}
                    >
                    <Background
                        backgroundRef={backgroundImageRef}
                        src={backgroundImage?.src || ''}
                        width={canvasWidth}
                        height={canvasHeight}
                    />
                    <canvas
                        className='overlay-canvas'
                        ref={canvasRef}
                        width={canvasWidth}
                        height={canvasHeight}
                        onPointerDown={(event) => startDrawing(event, ctx, brushColor, brushSize, setLines, setIsDrawing, setBrushPos)}
                        onPointerUp={() => finishDrawing(ctx, setIsDrawing)}
                        onPointerMove={(event) => draw(event, ctx, brushColor, brushSize, isDrawing, setLines, setBrushPos)}
                        onPointerLeave={() => handleMouseLeave(setShowBrush)}
                        onPointerEnter={() => handleMouseEnter(setShowBrush)}
                        onContextMenu={handleContextMenu}
                    />
                    <ShowBrushSreen
                        condition={showBrush}
                        brushPos={brushPos}
                        brushSize={brushSize}
                        brushColor={brushColor}
                    />
                    <BrushPreviewScreen
                        condition={brushPreview}
                        brushSize={brushSize}
                        brushColor={brushColor}
                    />
                    <LoadingScreen
                        condition={isLoading}
                        width={canvasWidth}
                        height={canvasHeight}
                    />
                    
                </div>
                </>
            ) : (
                <p>Loading image...</p>
            )}
            <footer className="footer" style={{ backgroundColor: '#8a8a97', maxWidth: "768px", color: "black"}}>
                <Row className="canvas-controls mt-5 px-4">
                    <div>
                        <BrushSlider
                            className="me-2"
                            brushSize={brushSize}
                            handleBrushSizeChange={handleBrushSizeChange}
                            handleBrushPreviewEnd={handleBrushPreviewEnd}
                        />
                    </div>
                </Row>

                <Row className="canvas-controls mt-5 px-3">
                    <LineRemoverButton
                        onClick={handleUndo}
                        disabled={lines.length === 0}
                        activeColor="black"
                    />
                    <EraseButton
                        onClick={() => handleInference(lines, backgroundImageRef, canvasRef, currentIndex, setLines, setCurrentIndex, setBgList, setIsLoading)}
                        disabled={lines.length === 0}  
                        activeColor='#e0da6a'
                    />
                    <DownloadButton
                        onClick={handleDownload}
                        disabled={currentIndex === 0}
                        activeColor="#0048c7"
                    />
                </Row>

                <Row className="canvas-controls mt-5 mb-5">
                    <PrevFrameButton
                        onClick={handleUndoBackground}
                        disabled={currentIndex === 0 || isLoading}
                        activeColor="#1f1fdf"
                    />
                    <NextFrameButton
                        onClick={handleRedoBackground}
                        disabled={currentIndex === bgList.length - 1 || isLoading}
                        activeColor="#1f1fdf"
                    />
                </Row>
            </footer>
        </Container>
        
    );
}

export default Canvas;