import { useEffect, useState } from 'react';

export const startDrawing = (event, ctx, brushColor, brushSize, setLines, setIsDrawing, setBrushPos) => {
    event.preventDefault();
    const bias = 0.00008
    

    const nativeEvent = event.nativeEvent;
    const { offsetX, offsetY } = nativeEvent;

    if (ctx) {
        setIsDrawing(true);
        setBrushPos({x: offsetX, y: offsetY});


        ctx.strokeStyle = brushColor;
        ctx.lineWidth = brushSize;

        setLines(prevLines => [
            ...prevLines,
            {
                color: ctx.strokeStyle,
                width: ctx.lineWidth,
                path: [{ x: offsetX, y: offsetY }, {x: offsetX + bias, y: offsetY+bias}]
            }
        ]);

        ctx.beginPath();
        ctx.moveTo(offsetX, offsetY);
        ctx.lineTo(offsetX + bias, offsetY + bias);
        ctx.stroke();

    }
};

export const finishDrawing = (ctx, setIsDrawing) => {
    if (ctx) {
        ctx.closePath();
        setIsDrawing(false);
    }
};

export const draw = (event, ctx, brushColor, brushSize, isDrawing, setLines, setBrushPos) => {
    const nativeEvent = event.nativeEvent;
    const { offsetX, offsetY } = nativeEvent;

    if (!ctx) return;

    setBrushPos({x: offsetX, y: offsetY});

    if (isDrawing) {
        ctx.strokeStyle = brushColor;
        ctx.lineWidth = brushSize;

        ctx.lineTo(offsetX, offsetY);
        ctx.stroke();

        // Update path points of the last line
        setLines(prevLines => {
            const updatedLines = [...prevLines];
            if (updatedLines.length > 0) {
                updatedLines[updatedLines.length - 1].path.push({ x: offsetX, y: offsetY });
            }
            return updatedLines;
        });
    }
};



export const useCanvasLayout = (backgroundImage, maxCanvasLayout) => {
    const [canvasLayout, setCanvasLayout] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (backgroundImage) {
            const updateCanvasSize = () => {
                const viewportWidth = window.innerWidth;
                const viewportHeight = window.innerHeight;

                const maxCanvasWidth = Math.min(maxCanvasLayout, viewportWidth - 2 * 40); // 40px margin
                const maxCanvasHeight = Math.min(maxCanvasLayout, viewportHeight);

                const imgWidth = backgroundImage.width;
                const imgHeight = backgroundImage.height;
                const aspectRatio = imgWidth / imgHeight;

                let width, height;

                if (imgWidth > maxCanvasWidth || imgHeight > maxCanvasHeight) {
                    if (imgWidth / maxCanvasWidth > imgHeight / maxCanvasHeight) {
                        width = maxCanvasWidth;
                        height = maxCanvasWidth / aspectRatio;
                    } else {
                        width = maxCanvasHeight * aspectRatio;
                        height = maxCanvasHeight;
                    }
                } else {
                    width = imgWidth;
                    height = imgHeight;
                }

                setCanvasLayout({ width: Math.round(width), height: Math.round(height) });
            };

            // Initial size setup
            if (canvasLayout.width === 0 && canvasLayout.height === 0) {
                updateCanvasSize();
            }

            console.log(backgroundImage, canvasLayout.width, canvasLayout.height);
        }
    }, [backgroundImage, maxCanvasLayout, canvasLayout]);

    return canvasLayout;
};

export const redrawCanvas = (canvasRef, ctx, lines) => {
    const canvas = canvasRef.current;
    if (canvas && ctx) {
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

        lines.forEach(line => {
            if (!line.path || line.path.length === 0) return; // Check if path is valid

            context.beginPath();
            context.moveTo(line.path[0].x, line.path[0].y);
            line.path.forEach(point => {
                context.lineTo(point.x, point.y);
            });
            context.strokeStyle = line.color;
            context.lineWidth = line.width;
            context.stroke();
        });
    }
};

export const useCanvasContext = (canvasRef, canvasWidth, canvasHeight) => {
    const [ctx, setctx] = useState(null);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            const context = canvas.getContext("2d");
            context.lineCap = "round";
            context.lineJoin = "round";
            setctx(context);
        }
    }, [canvasRef, canvasWidth, canvasHeight]);

    return ctx;
};