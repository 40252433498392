// FileContext.js
import React, { createContext, useState } from 'react';

// 파일 상태를 관리할 컨텍스트 생성
const FileContext = createContext();

// 파일 상태와 업데이트 함수를 제공하는 프로바이더 컴포넌트
function FileProvider({ children }) {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");

    return (
        <FileContext.Provider value={{ file, setFile, fileName, setFileName }}>
            {children}
        </FileContext.Provider>
    );
}

export { FileContext, FileProvider };
