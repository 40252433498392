import "./CanvasComponents.css"

export const Background = ({backgroundRef, src, width, height}) => {
    return (
        <img
            className='background-image'
            ref={backgroundRef}
            src={src}
            width={width}
            height={height}
            alt="Uploaded canvas background to remove object"
        />
    );
};

export const ShowBrushSreen = ({ condition, brushPos, brushSize, brushColor }) => {
    if (!condition) return null;

    return (
        <div
            style={{
                left: brushPos.x - brushSize / 2,
                top: brushPos.y - brushSize / 2,
                width: brushSize,
                height: brushSize,
                borderRadius: '50%',
                backgroundColor: brushColor,
                position: 'absolute',
                pointerEvents: 'none',
                opacity: 0.5,
                zIndex: 1000,
            }}
        />
    );
};

export const LoadingScreen = ({condition, width, height}) => {
    if (!condition) return null;

    return (
        <div
            className="loading-overlay"
            style={{
                width: width,
                height: height,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
        >
        <div className="loading-text">Erasing...</div>
    </div>
    )
}

export const BrushPreviewScreen = ({condition, brushSize, brushColor }) => {
    if (!condition) return null;

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                opacity: 0.6,
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    left: `calc(50% - ${brushSize / 2}px)`,
                    top: `calc(50% - ${brushSize / 2}px)`,
                    width: brushSize,
                    height: brushSize,
                    borderRadius: '50%',
                    backgroundColor: brushColor, // 브러시 색상
                    pointerEvents: 'none',
                }}
            />
        </div>

    )
}
