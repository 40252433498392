export const handleInference = async (lines,
                                      backgroundImageRef,
                                      canvasRef,
                                      currentIndex,
                                      setLines,
                                      setCurrentIndex,
                                      setBgList,
                                      setIsLoading,) => {
    if (lines.length > 0) {
        setIsLoading(true);
        const backgroundImage = backgroundImageRef.current;

        const getBackgroundDataURL = () => {
            return new Promise((resolve, reject) => {
              const bgCanvas = document.createElement('canvas');
              const context = bgCanvas.getContext('2d');
      
              if (!context) {
                reject(new Error('Failed to get canvas context'));
                return;
              }
      
              // 이미지가 로드되면 캔버스에 그림
              backgroundImage.onload = () => {
                try {
                  bgCanvas.width = backgroundImage.naturalWidth;
                  bgCanvas.height = backgroundImage.naturalHeight;
                  context.drawImage(backgroundImage, 0, 0);
                  const bgDataURL = bgCanvas.toDataURL('image/png');
                  resolve(bgDataURL);
                } catch (error) {
                  reject(error);
                }
              };
      
              // 이미지가 이미 로드된 경우
              if (backgroundImage.complete) {
                backgroundImage.onload();
              }
            });
          };

        const bgDataURL = await getBackgroundDataURL();

        const canvas = canvasRef.current;
        const canvasDataURL = canvas.toDataURL('image/png'); // 데이터 URL로 변환

        setLines([]);
        try {
            const response = await fetch('/api/inpainting-inference/', {
            // const response = await fetch('http://localhost:8000/inpainting-inference/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    background_image: bgDataURL,
                    canvas_image: canvasDataURL
                })
            });

            if (response.ok) {
                const result = await response.json();
                const predictedImageUrl = result.predicted_image;
                const imageResponse = await fetch(predictedImageUrl);
                if (!imageResponse.ok) {
                    throw new Error('Failed to fetch the predicted image');
                }
                const blob = await imageResponse.blob();
                const file = new File([blob], "predicted_image.png", {type: blob.type})

                setBgList(prevBgList => {
                    const updateBgList = prevBgList.slice(0, currentIndex + 1);
                    return [...updateBgList, file]
                })
                setCurrentIndex(prevIndex => prevIndex + 1);

                // setLines([]);
                console.log('Inpainting completed successfully');

            } else {
                console.error('Failed to perform inpainting');
            }
        } catch (error) {
            console.error('Error during inpainting:', error);
        } finally {
            setIsLoading(false);
        }
    } else {
        console.log("lines is empty")
    }
};