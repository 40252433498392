// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    }

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.loading-text {
    font-size: 24px;
    animation: blink 1s infinite;
    position: 'absolute';
    color: white;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/CanvasPage/CanvasComponents.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB;;AAEJ;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;IAChB;AACJ;;AAEA;IACI,eAAe;IACf,4BAA4B;IAC5B,oBAAoB;IACpB,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".background-image {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    pointer-events: none;\r\n    }\r\n\r\n.loading-overlay {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 9999;\r\n}\r\n\r\n@keyframes blink {\r\n    0%, 100% {\r\n        opacity: 1;\r\n    }\r\n    50% {\r\n        opacity: 0.5;\r\n    }\r\n}\r\n\r\n.loading-text {\r\n    font-size: 24px;\r\n    animation: blink 1s infinite;\r\n    position: 'absolute';\r\n    color: white;\r\n    user-select: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
