// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  touch-action: pan-y;
}

.App.canvas-background {
  background-color: #8a8a97; /* Canvas 페이지 배경색 */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB,EAAE,mBAAmB;AAChD","sourcesContent":[".App {\r\n  text-align: center;\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  touch-action: pan-y;\r\n}\r\n\r\n.App.canvas-background {\r\n  background-color: #8a8a97; /* Canvas 페이지 배경색 */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
