import React from 'react';
import { Form } from "react-bootstrap";
import { PiPaintBrushHouseholdLight } from "react-icons/pi";
import "./BrushComponents.css"

export const BrushSlider = ({brushSize, handleBrushSizeChange, handleBrushPreviewEnd}) => {
    return (
    <Form.Group controlId="brushSize" className="d-flex align-items-center ">
        <Form.Label className="d-flex align-items-center me-3">
        <PiPaintBrushHouseholdLight size={32} /></Form.Label>
        <Form.Control
            className="brush-slider"
            type="range"
            min="5"
            max="70"
            value={brushSize}
            onChange={handleBrushSizeChange}
            onMouseUp={handleBrushPreviewEnd}
            onTouchEnd={handleBrushPreviewEnd}
            
        />
    </Form.Group>
    )
};