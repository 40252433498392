import { Col } from "react-bootstrap";
import { CiEraser } from "react-icons/ci";
import { SlMagicWand } from "react-icons/sl";
import { ImDownload3 } from "react-icons/im";
import { GrPrevious, GrNext } from "react-icons/gr";

import "./ButtonComponents.css";

const buttonIconSize = 25;
const frameButtonIconSize = 20;
const inActiveColor = '#c5c6c7';

const Button = ({ onClick, Icon, name, size, disabled, inActiveColor, activeColor }) => {
    return (
        <Col>
            <button
                className="btn button-layout"
                onClick={onClick}
                disabled={disabled}
                style={{color: disabled ? inActiveColor : "black"}}
            >
                <Icon
                    size={size}
                    style={{color: disabled ? inActiveColor : activeColor}}
                />
                <br />
                {name}
            </button>
        </Col>
    )
}

export const LineRemoverButton = (props) => {
    return(
        <Button
            {...props}
            Icon={CiEraser}
            name="Line Remover"
            size={buttonIconSize}
            inActiveColor={inActiveColor}
        />
    );
};

export const EraseButton = (props) => {
    return(
        <Button
            {...props}
            Icon={SlMagicWand}
            name="Erase"
            size={buttonIconSize}
            inActiveColor={inActiveColor}
        />
    );
};

export const DownloadButton = (props) => {
    return(
        <Button
            {...props}
            Icon={ImDownload3}
            name="Download"
            size={buttonIconSize}
            inActiveColor={inActiveColor}
        />
    );
};

export const PrevFrameButton = (props) => {
    return(
        <Button
            {...props}
            Icon={GrPrevious}
            name="Prev Frame"
            size={frameButtonIconSize}
            inActiveColor={inActiveColor}
        />
    );
};

export const NextFrameButton = (props) => {
    return(
        <Button
            {...props}
            Icon={GrNext}
            name="Next Frame"
            size={frameButtonIconSize}
            inActiveColor={inActiveColor}
        />
    );
};