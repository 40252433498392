// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    touch-action: pan-y;
}

@media (min-width: 768px) {
    .canvas-container {
        min-width: 768px;
    }
}

.canvas-controls {
    width: var(--canvas-width);
    margin-top: 10px;
    gap: 1px;
    justify-content: center;
    align-items: center;
}
  
.canvas-overlay-container {
position: relative;
margin: auto;
width: var(--canvas-width);
height: var(--canvas-height);
overflow: hidden;
touch-action: none;
}

.overlay-canvas {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
opacity: 0.5;
cursor: none;
user-select: none;
-webkit-touch-callout: none;
-webkit-user-select: none;
touch-action: none;
}

.footer {
    background-color: #8a8a97;
}`, "",{"version":3,"sources":["webpack://./src/pages/CanvasPage/Canvas.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,QAAQ;IACR,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;AACA,kBAAkB;AAClB,YAAY;AACZ,0BAA0B;AAC1B,4BAA4B;AAC5B,gBAAgB;AAChB,kBAAkB;AAClB;;AAEA;AACA,kBAAkB;AAClB,MAAM;AACN,OAAO;AACP,WAAW;AACX,YAAY;AACZ,YAAY;AACZ,YAAY;AACZ,iBAAiB;AACjB,2BAA2B;AAC3B,yBAAyB;AACzB,kBAAkB;AAClB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".canvas-container {\r\n    width: 100%;\r\n    height: 100%;\r\n    position: relative;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin: auto;\r\n    flex-direction: column;\r\n    touch-action: pan-y;\r\n}\r\n\r\n@media (min-width: 768px) {\r\n    .canvas-container {\r\n        min-width: 768px;\r\n    }\r\n}\r\n\r\n.canvas-controls {\r\n    width: var(--canvas-width);\r\n    margin-top: 10px;\r\n    gap: 1px;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n  \r\n.canvas-overlay-container {\r\nposition: relative;\r\nmargin: auto;\r\nwidth: var(--canvas-width);\r\nheight: var(--canvas-height);\r\noverflow: hidden;\r\ntouch-action: none;\r\n}\r\n\r\n.overlay-canvas {\r\nposition: absolute;\r\ntop: 0;\r\nleft: 0;\r\nwidth: 100%;\r\nheight: 100%;\r\nopacity: 0.5;\r\ncursor: none;\r\nuser-select: none;\r\n-webkit-touch-callout: none;\r\n-webkit-user-select: none;\r\ntouch-action: none;\r\n}\r\n\r\n.footer {\r\n    background-color: #8a8a97;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
