import { useState, useEffect } from 'react';

const LoadImage = (file) => {
    const [imageURL, setImageURL] = useState(null);
    const [backgroundImage, setbackgroundImage] = useState(null);

    useEffect(() => {
        if (file) {
            // 기존 URL 객체가 있으면 먼저 해제합니다.
            if (imageURL) {
                URL.revokeObjectURL(imageURL);
            }

            // 새로운 URL 객체를 생성하고 상태에 저장합니다.
            const url = URL.createObjectURL(file);
            setImageURL(url);

            const img = new window.Image();
            img.src = url;
            img.onload = () => {
                setbackgroundImage(img);
            };
        }
    }, [file]);

    return backgroundImage;
};

export default LoadImage;


